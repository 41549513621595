<template>
  <span :class="[ 'icon', `icon-${symbol}`, `icon--${size}` ]" />
</template>

<style>
  .icon--large {
    font-size: 32px !important;
  }
</style>

<script>
  export default {
    name: 'Icon',

    props: {
      symbol: {
        type: String,
        required: true
      },
      size: {
        type: String,
        required: false
      }
    }
  }
</script>
